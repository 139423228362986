import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  totalRbooksCount: 0,
  unassignedRbooksCount: 0,
  activeRbooksCount: 0,
  closedRbooksCount: 0,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "rbooksCounts",
  initialState,
  reducers: {
    fetchCountsStart: (state) => {
      state.loading = true;
    },
    fetchCountsSuccess: (state, { payload }) => {
      state.totalRbooksCount = payload.totalRbooksCount;
      state.unassignedRbooksCount = payload.unassignedRbooksCount;
      state.activeRbooksCount = payload.activeRbooksCount;
      state.closedRbooksCount = payload.closedRbooksCount;
      state.loading = false;
    },
    fetchCountsError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const { fetchCountsStart, fetchCountsSuccess, fetchCountsError } = slice.actions;

export default slice.reducer;

// Async thunk to fetch the counts
export const fetchTotalCounts = (accessToken) => async (dispatch) => {
  dispatch(fetchCountsStart());

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_API}/get-rbooks-counts`,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    const {
      totalRbooksCount,
      unassignedRbooksCount,
      activeRbooksCount,
      closedRbooksCount,
    } = response.data;

    dispatch(
      fetchCountsSuccess({
        totalRbooksCount,
        unassignedRbooksCount,
        activeRbooksCount,
        closedRbooksCount,
      })
    );
  } catch (error) {
    dispatch(fetchCountsError(error.message));
  }
};
