import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box, Stack } from "@mui/material";
// components
import Page from "./Page";
import logo404 from 'assets/illustration_404.svg'
// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme?.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404({ toDashboard, toLogin }) {
  return (
    <Page title="404">
      <Container>
        <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
          <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>

          <Typography sx={{ color: "text.secondary" }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
            mistyped the URL? Be sure to check your spelling.
          </Typography>

          <Box
            component="img"
            src={logo404}
            sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
          />

          <Stack direction={{ xs: "column", sm: "row" }} gap={3}>
            <Button
              to={toDashboard || "/dashboard/app"}
              size="large"
              variant="contained"
              component={RouterLink}
            >
              Back to Home
            </Button>
          </Stack>
        </ContentStyle>
      </Container>
    </Page>
  );
}
