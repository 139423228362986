import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  totalReceiptsCount: 0,
  pendingReceiptsCount: 0,
  usedReceiptsCount: 0,
  duplicateReceiptsCount: 0,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "receiptsCounts",
  initialState,
  reducers: {
    fetchCountsStart: (state) => {
      state.loading = true;
    },
    fetchCountsSuccess: (state, { payload }) => {
      state.totalReceiptsCount = payload.totalReceiptsCount;
      state.pendingReceiptsCount = payload.pendingReceiptsCount;
      state.usedReceiptsCount = payload.usedReceiptsCount;
      state.duplicateReceiptsCount = payload.duplicateReceiptsCount;
      state.loading = false;
    },
    fetchCountsError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const { fetchCountsStart, fetchCountsSuccess, fetchCountsError } = slice.actions;

export default slice.reducer;

// Async thunk to fetch the counts
export const fetchTotalCounts = (accessToken) => async (dispatch) => {
  dispatch(fetchCountsStart());

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_API}/get-receipts-counts`,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    const {
      totalReceiptsCount,
      pendingReceiptsCount,
      usedReceiptsCount,
      duplicateReceiptsCount,
    } = response.data;

    dispatch(
      fetchCountsSuccess({
        totalReceiptsCount,
        pendingReceiptsCount,
        usedReceiptsCount,
        duplicateReceiptsCount,
      })
    );
  } catch (error) {
    dispatch(fetchCountsError(error.message));
  }
};
