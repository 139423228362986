import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  rbooks: {
    data: [], // Holds all records
    currentPage: 0,
    numberOfPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "rbook",
  initialState,
  reducers: {
    getRbookSuccess: (state, { payload }) => {
      // Replace existing data with new data
      state.rbooks.data = payload.data;
      state.rbooks.currentPage = payload.currentPage;
      state.rbooks.numberOfPages = payload.numberOfPages;
      state.loading = false;
    },
    startLoadingRbook: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    updatePagination: (state, { payload }) => {
      state.rbooks.currentPage = payload.currentPage;
      state.rbooks.numberOfPages = payload.numberOfPages;
    },
  },
});

export default slice.reducer;

// export const getAllRbooks = ({ accessToken, filters, setRecordsPerPage }) => {
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: accessToken,
//   };

//   // Construct the query string with optional filters
//   const queryParams = new URLSearchParams({
//     ...(filters.filterIssuedTo && { filterIssuedTo: filters.filterIssuedTo }),
//     ...(filters.filterMda && { filterMda: filters.filterMda }),
//     ...(filters.filterStartDate && { filterStartDate: filters.filterStartDate }),
//     ...(filters.filterEndDate && { filterEndDate: filters.filterEndDate }),
//     ...(filters.filterName && { filterName: filters.filterName }),
//   }).toString();

//   return async (dispatch) => {
//     try {
//       dispatch(slice.actions.startLoadingRbook());
//       const res = await axios.get(
//         `${process.env.REACT_APP_BACKEND_API}/get-all-rbooks?${queryParams}`,
//         { headers }
//       );
//       dispatch(slice.actions.getRbookSuccess(res.data));
//       // setRecordsPerPage(100);
//     } catch (error) {
//       dispatch(slice.actions.hasError(error.message));
//     }
//   };
// };

export const getAllRbooks = ({ accessToken, filters = {}, setRecordsPerPage }) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `${accessToken}`,  // Ensure the token is properly formatted if needed
  };

  // Ensure filters is an object and clean it by removing any undefined, null, or empty values
  const cleanedFilters = Object.fromEntries(
    Object.entries(filters || {}).filter(
      ([_, value]) => value !== undefined && value !== null && value !== ''
    )
  );

  // Construct the query string with cleaned filters
  const queryParams = new URLSearchParams(cleanedFilters).toString();

  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingRbook());

      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-all-rbooks?${queryParams}`,
        { headers }
      );

      dispatch(slice.actions.getRbookSuccess(res.data));
      // Optionally set the number of records per page
      // setRecordsPerPage(100);
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};


// Action to manually update pagination if needed
export const updatePagination = (page) => (dispatch, getState) => {
  const { rbooks } = getState().rbook;
  const newPagination = {
    currentPage: page,
    numberOfPages: rbooks.numberOfPages,
  };
  dispatch(slice.actions.updatePagination(newPagination));
};
