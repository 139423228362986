import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  totalContractsCount: 0,
  pendingContractsCount: 0,
  reviewContractsCount: 0,
  waitingApprovalContractsCount: 0,
  approvedContractsCount: 0,
  activeContractsCount: 0,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "contractsCounts",
  initialState,
  reducers: {
    fetchCountsStart: (state) => {
      state.loading = true;
    },
    fetchCountsSuccess: (state, { payload }) => {
      state.totalContractsCount = payload.totalContractsCount;
      state.pendingContractsCount = payload.pendingContractsCount;
      state.reviewContractsCount = payload.reviewContractsCount;
      state.waitingApprovalContractsCount = payload.waitingApprovalContractsCount;
      state.approvedContractsCount = payload.approvedContractsCount;
      state.activeContractsCount = payload.activeContractsCount;

      state.loading = false;
    },
    fetchCountsError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const { fetchCountsStart, fetchCountsSuccess, fetchCountsError } = slice.actions;

export default slice.reducer;

// Async thunk to fetch the counts
export const fetchTotalCounts = (accessToken) => async (dispatch) => {
  dispatch(fetchCountsStart());

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_API}/get-contracts-counts`,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    const {
      totalContractsCount,
      pendingContractsCount,
      reviewContractsCount,
      waitingApprovalContractsCount,
      approvedContractsCount,
      activeContractsCount,

    } = response.data;

    dispatch(
      fetchCountsSuccess({
        totalContractsCount,
        pendingContractsCount,
        reviewContractsCount,
        waitingApprovalContractsCount,
        approvedContractsCount,
        activeContractsCount
      })
    );
  } catch (error) {
    dispatch(fetchCountsError(error.message));
  }
};
