// countsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchTotalCounts = createAsyncThunk(
  'counts/fetchTotalCounts',
  async (accessToken) => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_API}/get-total-counts`, {
      headers: {
        Authorization: `${accessToken}`,
      },
    });
    return response.data;
  }
);

const countsSlice = createSlice({
  name: 'counts',
  initialState: {
    totalRbooks: 0,
    totalReceipts: 0,
    totalContracts: 0,
    expiringContracts: [],
    logs: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTotalCounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTotalCounts.fulfilled, (state, action) => {
        state.totalRbooks = action.payload.totalRbooks;
        state.totalReceipts = action.payload.totalReceipts;
        state.totalContracts = action.payload.totalContracts;
        state.expiringContracts = action.payload.expiringContracts;
        state.logs = action.payload.logs;
        state.loading = false;
      })
      .addCase(fetchTotalCounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default countsSlice.reducer;
