import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  fmiss: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "fmis",
  initialState,
  reducers: {
    getFmisSuccess: (state, { payload }) => {
      state.fmiss = payload;
      state.loading = false;
    },
    startLoadingFmis: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default slice.reducer;

export const getAllFmiss = ({ accessToken, page = 1, startDate, endDate }) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: accessToken,
  };

  // Create the filters object
  const filters = {
    page,
    startDate,
    endDate,
  };

  // Clean the filters to remove undefined, null, or empty values
  const cleanedFilters = Object.fromEntries(
    Object.entries(filters).filter(
      ([_, value]) => value !== undefined && value !== null && value !== ""
    )
  );

  // Construct the query parameters using URLSearchParams
  const params = new URLSearchParams(cleanedFilters).toString();

  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingFmis());

      // Make the API call with cleaned query parameters for date range and pagination
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-all-fmiss?${params}`,
        { headers }
      );

      dispatch(slice.actions.getFmisSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};

