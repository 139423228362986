import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  contracts: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  rejectedContracts: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  archivedContracts: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    getContractSuccess: (state, { payload }) => {
      state.contracts = payload;
      state.loading = false;
    },
    getTerminatedContractsSuccess: (state, { payload }) => {
      state.rejectedContracts = payload;
      state.loading = false;
    },
    getArchivedContractsSuccess: (state, { payload }) => {
      state.archivedContracts = payload;
      state.loading = false;
    },
    startLoadingContract: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default slice.reducer;

export const getAllContracts = ({ accessToken, params = {}, setRecordsPerPage }) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `${accessToken}`, // Ensure the token is properly formatted
  };

  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingContract());

      // Clean params by removing undefined, null, or empty string values
      const cleanParams = Object.fromEntries(
        Object.entries(params || {}).filter(
          ([_, value]) => value !== undefined && value !== null && value !== ""
        )
      );

      // Construct query string from the cleaned params
      const queryParams = new URLSearchParams(cleanParams).toString();

      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-all-contract?${queryParams}`,
        { headers }
      );
      
      dispatch(slice.actions.getContractSuccess(res.data));
      setRecordsPerPage(100 || 10); // Set default records per page if not provided
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};

// Action to fetch terminated contracts
export const getTerminatedContracts = ({ accessToken, params = {}, setRecordsPerPage }) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `${accessToken}`,
  };

  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingContract());

      // Clean params by removing undefined, null, or empty string values
      const cleanParams = Object.fromEntries(
        Object.entries(params || {}).filter(
          ([_, value]) => value !== undefined && value !== null && value !== ""
        )
      );

      // Construct query string from the cleaned params
      const queryParams = new URLSearchParams(cleanParams).toString();

      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-rejected-contracts?${queryParams}`,
        { headers }
      );
      
      dispatch(slice.actions.getTerminatedContractsSuccess(res.data));
      setRecordsPerPage(100 || 10);
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};

// Action to fetch archived contracts
export const getArchivedContracts = ({ accessToken, params = {}, setRecordsPerPage }) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `${accessToken}`,
  };

  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingContract());

      // Clean params by removing undefined, null, or empty string values
      const cleanParams = Object.fromEntries(
        Object.entries(params || {}).filter(
          ([_, value]) => value !== undefined && value !== null && value !== ""
        )
      );

      // Construct query string from the cleaned params
      const queryParams = new URLSearchParams(cleanParams).toString();

      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-archived-contracts?${queryParams}`,
        { headers }
      );
      
      dispatch(slice.actions.getArchivedContractsSuccess(res.data));
      setRecordsPerPage(100 || 10);
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};

