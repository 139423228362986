import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
// material
import { styled, useTheme } from "@mui/material/styles";
//
import { reset } from "redux/slices/auth.slice";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import { checkToken } from "redux/thunk/auth.thunk";

import useAuth from "hooks/useAuth";
import toast from "react-hot-toast";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme?.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme?.spacing(2),
    paddingRight: theme?.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();

  const navigate = useNavigate();
  // const { logout } = useAuth();
  const { accessToken, user } = useAuth();

  useEffect(() => {
    const Abort = new AbortController();

    const handleExpiredToken = async () => {
      await dispatch(reset());
      toast.error("Session expired!");
      navigate("/", { replace: true });
      clearBrowserData();
    };

    const tokenCheck = async (token) => {
      const res = await dispatch(checkToken(token));
      if (res.type === "auth/token-check/rejected") {
        handleExpiredToken();
      }
    };

    tokenCheck(accessToken);

    
    // abort controller
    return () => {
      Abort.abort();
    };
  }, [accessToken, navigate, dispatch, user]);

  const clearBrowserData = async () => {  
    try {
      // Clear localStorage
      localStorage.clear();
  
      // Clear sessionStorage
      sessionStorage.clear();
  
      // Clear IndexedDB
      indexedDB.databases().then(databases => {
        databases.forEach(db => {
          indexedDB.deleteDatabase(db.name);
        });
      });
  
      // Clear Cache API
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map(name => caches.delete(name)));
  
    } catch (error) {
      console.error("Error clearing browser data:", error);
    }
  };
  
  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle
        sx={{
          transition: theme.transitions.create("margin", {
            duration: theme.transitions.duration.complex,
          }),
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
