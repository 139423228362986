import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  receipts: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "receipt",
  initialState,
  reducers: {
    getReceiptSuccess: (state, { payload }) => {
      state.receipts = payload;
      state.loading = false;
    },
    startLoadingReceipt: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default slice.reducer;

export const getAllReceipts = ({ accessToken, filters = {}, page = 1 }) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `${accessToken}`, // Ensure the token is properly formatted
  };

  // Clean filters by removing undefined, null, or empty string values
  const cleanedFilters = Object.fromEntries(
    Object.entries(filters || {}).filter(
      ([_, value]) => value !== undefined && value !== null && value !== ''
    )
  );

  // Construct query parameters from cleaned filters and page
  const params = new URLSearchParams({
    ...cleanedFilters,  // Include the cleaned filters in the query
    page,               // Use the provided page or default to 1
  }).toString();        // Convert to query string

  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoadingReceipt());

      // Make the API call with the query parameters
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-all-receipts?${params}`,
        { headers }
      );

      dispatch(slice.actions.getReceiptSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};



