import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  supports: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "support",
  initialState,
  reducers: {
    getAllSupportSuccess: (state, action) => {
      state.supports = action.payload;
      state.loading = false;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default slice.reducer;

export const getAllSupports = ({ params = {}, accessToken }) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: accessToken,
  };
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());
      // Clean params by removing undefined, null, or empty string values
      const cleanParams = Object.fromEntries(
        Object.entries(params || {}).filter(
          ([_, value]) => value !== undefined && value !== null && value !== ""
        )
      );

      // Construct query string from the cleaned params
      const queryParams = new URLSearchParams(cleanParams).toString();

      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-all-supports?${queryParams}`,
        { headers }
      );
      dispatch(slice.actions.getAllSupportSuccess(res.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
};
