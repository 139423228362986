import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  logs: {
    data: [],
    currentPage: 0,
    numberOfPages: 0,
  },
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    getLogSuccess: (state, {payload}) => {
      state.logs = payload;
      state.loading = false;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default slice.reducer;

// export const getLogs = ({ accessToken, page }) => {
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: accessToken,
//   };
//   return async (dispatch) => {
//     try {
//       dispatch(slice.actions.startLoading());
//       const res = await axios.get(
//         `${process.env.REACT_APP_BACKEND_API}/get-all-logs`,
//         { headers }
//       );
//       dispatch(slice.actions.getLogSuccess(res.data));
//     } catch (error) {
//       console.log(error.message);
      
//       dispatch(slice.actions.hasError(error.message));
//     }
//   };
// };

export const getLogs = ({ accessToken, page, filterName, filterStatus, startDate, endDate }) => {
  return async (dispatch) => {
    // Prepare the headers with the access token
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${accessToken}`, // Include the accessToken as a Bearer token
    };

    // Prepare query params with the available filters
    const queryParams = new URLSearchParams();
    if (filterName) queryParams.append("filterName", filterName);
    if (filterStatus) queryParams.append("filterStatus", filterStatus);
    if (startDate) queryParams.append("startDate", startDate);
    if (endDate) queryParams.append("endDate", endDate);

    try {
      // Dispatch action to indicate loading has started
      dispatch(slice.actions.startLoading());

      // Send request to fetch logs with filters and access token
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/get-all-logs?${queryParams.toString()}`, 
        { headers }
      );

      // Dispatch success action with the logs data
      dispatch(slice.actions.getLogSuccess(res.data));
    } catch (error) {
      console.error("Error fetching logs:", error.message);

      // Dispatch error action with error message
      dispatch(slice.actions.hasError(error.message));
    }
  };
};