import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  totalFmisCount: 0,
  pendingFmisCount: 0,
  usedFmisCount: 0,
  duplicateFmisCount: 0,
  invalidFmisCount: 0,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "fmisCounts",
  initialState,
  reducers: {
    fetchFmisCountsStart: (state) => {
      state.loading = true;
    },
    fetchFmisCountsSuccess: (state, { payload }) => {
      state.totalFmisCount = payload.totalFmisCount;
      state.pendingFmisCount = payload.pendingFmisCount;
      state.usedFmisCount = payload.usedFmisCount;
      state.duplicateFmisCount = payload.duplicateFmisCount;
      state.invalidFmisCount = payload.invalidFmisCount;
      state.loading = false;
    },
    fetchFmisCountsError: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export const {
  fetchFmisCountsStart,
  fetchFmisCountsSuccess,
  fetchFmisCountsError,
} = slice.actions;

export default slice.reducer;

// Async thunk to fetch the FMIS counts
export const fetchFmisCounts = (accessToken) => async (dispatch) => {
  dispatch(fetchFmisCountsStart());

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_API}/get-fmis-counts`,
      {
        headers: {
          Authorization: accessToken,
        },
      }
    );

    const {
      totalFmisCount,
      pendingFmisCount,
      usedFmisCount,
      duplicateFmisCount,
      invalidFmisCount,
    } = response.data;

    dispatch(
      fetchFmisCountsSuccess({
        totalFmisCount,
        pendingFmisCount,
        usedFmisCount,
        duplicateFmisCount,
        invalidFmisCount,
      })
    );
  } catch (error) {
    dispatch(fetchFmisCountsError(error.message));
  }
};
